<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">用户管理</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>

    <div class="u-container">
      <div class="u-container-item" style="padding-right: 15px">
        <div>修改用户信息</div>
        <div class="form-item" style="margin-top: 20px">
	        <v-text-field
			        :value="item.username"
			        dense
			        disabled
			        label="用户名"
			        readonly
			        outlined
	        >
	        </v-text-field>
        </div>
	      <div class="form-item">
		      <v-text-field v-model="item.telephone" dense label="手机号" outlined>
		      </v-text-field>
	      </div>
	      <div class="form-item">
		      <v-text-field v-model="item.email" dense label="邮箱" outlined>
		      </v-text-field>
	      </div>
	      <div class="form-item">
		      <v-text-field
				      v-model="item.organization"
				      dense
				      label="公司"
				      outlined
		      >
		      </v-text-field>
	      </div>

	      <v-btn color="primary" @click="saveBasicInfo()">确定修改</v-btn>
      </div>
	    <div class="u-container-item" style="padding-left: 15px">
		    <div>修改密码</div>
		    <div class="form-item" style="margin-top: 20px">
			    <v-text-field v-model="old_password" dense label="旧密码" outlined type="password">
			    </v-text-field>
		    </div>
		    <div class="form-item">
			    <v-text-field v-model="new_password1" dense label="新密码" outlined type="password">
			    </v-text-field>
		    </div>
		    <div class="form-item">
			    <v-text-field v-model="new_password2" dense label="确认新密码" outlined type="password">
			    </v-text-field>
		    </div>

		    <v-btn color="primary" @click="changePassword">确定修改</v-btn>
	    </div>
    </div>
  </v-container>
</template>
<script>
import {getUserDetail, updateUserBasicInfo} from "@/api/users";
import {changePassword} from "@/api/auth";
import {mapState} from "vuex";

export default {
	name: "userHome",
	props: {
		id: [String, Number],
	},
	data: () => ({
		item: {},
		old_password: "",
		new_password1: "",
    new_password2: "",
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
	  saveBasicInfo() {
		  let data = {...this.item};
		  console.log("data", data);
		  updateUserBasicInfo(data);
	  },
	  changePassword() {
		  changePassword({
			  old_password: this.old_password,
			  new_password1: this.new_password1,
			  new_password2: this.new_password2
		  }).then((res) => {
			  alert('密码修改成功')
		  }).catch(error => {
			  console.log('error', error.response)
			  alert('错误：' + JSON.stringify(error.response.data))
		  })
	  },
  },
  mounted() {
    if (this.id) {
      getUserDetail(this.id).then((result) => {
        this.item = result;
      });
    } else {
      let id = null;
      if (this.user) id = this.user.id;
      else {
        let user = window.localStorage.getItem("user");
        if (user) {
          id = JSON.parse(user).id;
        }
      }
      if (id) {
        getUserDetail(id).then((result) => {
          this.item = result;
        });
      }
    }
  },
};
</script>
<style lang="stylus">
.n-title
	font-size 32px

.u-container
	display flex

	.u-container-item
		flex 1

		.form-item
			margin-top: -10px
</style>
