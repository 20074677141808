import request from "@/request";
/*
 * @Description: 注册接口
 * @Author: 张宇童
 * @Date: 2020/9/6 14:58
 */
export const registration = data =>
		request.post("/api/auth/registration/", data);
/*
 * @Description: 登录接口
 * @Author: 张宇童
 * @Date: 2020/9/6 17:05
 */
export const login = data => request.post("/api/auth/login/", data);
export const changePassword = data =>
		request.post("/api/auth/password/change/", data);

export const refresh = data => request.post("/api/admin/token/refresh/",data)
